<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Hitung Honor Mengajar<br />
        <span class="text-sm font-medium" data-v-f48bdcdc=""
          >Perhitungan Honor Mengajar</span
        >
      </h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <AlertFailed v-if="alertFailed" :message="messageAlert" />

      <div class="md:flex mb-4 gap-2">
        <select v-model="pilSemester" class="form-select mb-2">
          <option
            v-for="semester in listSemester"
            :key="semester.id_smt"
            :value="semester.id_smt"
          >
            {{ semester.nm_smt }}
          </option>
        </select>
        <input v-model="piltgl1" type="date" class="form-control mb-2" />
        <input v-model="piltgl2" type="date" class="form-control mb-2" />
        <button class="btn btn-primary mb-2" @click="getDataHitungNgajar()">
          <SearchIcon></SearchIcon> Tampilkan
        </button>
      </div>

      <button
        v-if="listhitungan.length > 0"
        class="btn btn-outline-secondary rounded mb-2"
        @click="generateExcel()"
      >
        <DownloadIcon class="mr-1"></DownloadIcon> Cetak
      </button>

      <div
        v-if="listhitungan.length > 0"
        class="intro-y block sm:flex items-center my-4 gap-3 rounded bg-primary-7 p-4"
      >
        <label class="sm:ml-auto font-bold"
          >Melakukan Penyimpanan ke Pendataan Honor :</label
        >
        <select v-model="pilBulan" class="sm:mt-0 form-select w-36">
          <option value="" selected disabled>Pilih Bulan</option>
          <option v-for="bulan in listBulan" :key="bulan.id" :value="bulan.id">
            {{ bulan.name }}
          </option>
        </select>
        <button
          class="btn btn-outline-primary hover:text-white"
          @click="simpanHonor()"
        >
          Simpan
        </button>
      </div>

      <div class="my-2 p-2">
        <input
          v-model="searchFilter"
          class="form-control pull-right"
          placeholder="Ketik 3 Kata KODE / NAMA DOSEN"
          @keyup="filterName"
        />
      </div>

      <table class="table">
        <thead>
          <tr class="bg-gray-600 text-white text-center">
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              No
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Tahun Ajar
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Kode MK
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Nama MK
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Kelas
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Sesi Kuliah
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              SKS MK
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Bobot SKS
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              NM Dosen
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Jabatan
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Jml <br />
              Pertemuan
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Honor
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Jumlah
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in filterListhitungan"
            v-if="filterListhitungan.length > 0"
            :key="index"
            class="hover:bg-gray-300 odd:bg-gray-200"
          >
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
            >
              {{ index + 1 }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
            >
              {{ item.thnajar }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
            >
              {{ item.kode_mk }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
            >
              {{ item.nm_mk }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
            >
              {{ item.nm_kls }}
            </td>
            <td
              class="font-bold border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
              :class="item.sesi == 'P' ? 'text-blue-500' : 'text-yellow-600'"
            >
              {{ item.sesi == "P" ? "PAGI" : "SORE" }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
            >
              {{ item.sks_mk }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40"
            >
              {{ item.bobot_sks }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              <div class="text-gray-600">::: {{ item.kode_dosen }}</div>
              <div v-if="item.STATUS_DOSEN == 1" class="text-green-500">
                DOSEN TETAP
              </div>
              <div v-else class="text-purple-500">DOSEN LB</div>

              <h1 class="font-medium">
                {{ item.NM_DOSEN }} ({{
                  item.gelar
                    ? item.gelar
                    : item.THNS3 > 0 && item.THNS3 != null
                    ? "S3"
                    : item.THNS2 > 0 && item.THNS2 != null
                    ? "S2"
                    : null
                }})
              </h1>
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ item.nm_jabfung ? item.nm_jabfung : item.nm_jabfung_lb }}
              <div class="text-small text-gray-500">
                {{
                  item?.angka_kredit
                    ? item?.angka_kredit
                    : item?.angka_kredit_lb
                }}
              </div>
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              <button @click="getPertemuan(item)">
                {{ item.jml_pertemuan }} <SearchIcon class="w-4"></SearchIcon>
              </button>
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40 text-right"
            >
              {{ rupiahLocal(item.honor) }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40 text-right"
            >
              <div class="text-gray-600">
                Rincian: <br />
                {{ item.honor }} * {{ item.jml_pertemuan }} *
                {{ item.bobot_sks == 4 ? 2 : item.bobot_sks }}
              </div>
              <div class="font-bold text-lg">
                {{ rupiahLocal(item.jumlah) }}
              </div>
            </td>
          </tr>
          <tr v-else class="text-center">
            <td colspan="13" class="border">Belum ada Data</td>
          </tr>
        </tbody>
      </table>
    </div>

    <SideOver :open="panel.panelOpen" :size="'max-w-5xl'" @close="closePanel">
      <template #title>Detail Pertemuan Mengajar</template>
      <div class="h-full" aria-hidden="true"></div>
      <RINCI :detailrinci="idData" />
    </SideOver>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import moment from "moment";
import { keuangan } from "@/services/models";
import { HTTP, HTTPKEU } from "@/services/http";
import RINCI from "./DetailPertemuan";
import SideOver from "@/layouts/main/SlideOver";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertFailed from "@/components/alerts/AlertFailed.vue";

export default defineComponent({
  components: {
    RINCI,
    SideOver,
    AlertSuccess,
    AlertFailed,
  },
  setup() {
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });

    return {
      panel,
      panelOpen,
    };
  },
  data() {
    return {
      pilSemester: 0,
      piltgl1: moment().format("YYYY-MM-DD"),
      piltgl2: moment().format("YYYY-MM-DD"),
      listhitungan: [],
      listSemester: [],
      listHonor: [],
      idData: [],
      listBulan: [],
      pilBulan: "",
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      searchFilter: "",
      filterListhitungan: [],
    };
  },
  watch: {
    pilSemester: {
      handler: function () {
        this.getListHonorarium();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getActiveSemester();
    // this.getDataHitungNgajar();
  },
  methods: {
    moment: function (date) {
      moment.locale("id");
      return moment(date);
    },
    panelPop() {
      this.panel.panelOpen = true;
    },
    closePanel(close) {
      this.panel.panelOpen = close;
    },
    rupiahLocal(nominal) {
      let rupiahLocal = Intl.NumberFormat("id-ID");
      if (nominal != null) {
        return "Rp. " + rupiahLocal.format(nominal);
      } else {
        return "0";
      }
    },
    getActiveSemester() {
      HTTP.get("is_active/semester_keu").then((res) => {
        let act = res.data.data.filter((prm) => prm.a_periode_aktif === 1);
        this.pilSemester = act[0].id_smt;
        this.listSemester = res.data.data;
      });
    },
    getListHonorarium() {
      HTTPKEU.get("list/honorarium/getListHonor/" + this.pilSemester).then(
        (res) => {
          this.listHonor = res.data;
        }
      );
    },
    getDataHitungNgajar() {
      this.alertSuccess = false;
      this.alertFailed = false;

      let prm = {
        id_smt: this.pilSemester,
        tgl1: this.piltgl1,
        tgl2: this.piltgl2,
      };
      this.listhitungan = [];
      keuangan.PerhiutnganHonor.postCustomPath("honor-ngajar", prm).then(
        (res) => {
          res.data.forEach((item) => {
            let value = {
              sesi: item.sesi,
              thnajar: item.thnajar,
              status_dosen: item.STATUS_DOSEN,
              id_jabfung: item.id_jabfung
                ? item.id_jabfung
                : item.id_jabfung_lb,
              gelar: item.gelar
                ? item.gelar
                : item.THNS3 > 0 && item.THNS3 != null
                ? "S3"
                : item.THNS2 > 0 && item.THNS2 != null
                ? "S2"
                : null,
            };

            let getHonor;
            if (value.sesi == "S") {
              getHonor = this.listHonor.filter((list) => {
                if (
                  value.gelar ===
                    list.master_kelompok_honor.nm_kelompok_honor &&
                  parseFloat(value.thnajar) === list.tahun_anggaran &&
                  value.sesi === list.kelompok_kelas &&
                  list.id_jabfung ===
                    (value.id_jabfung
                      ? value.id_jabfung
                      : "31.000000000000000000000000000000")
                ) {
                  return list;
                }
              });
            } else if (value.status_dosen == 2) {
              getHonor = this.listHonor.filter((list) => {
                if (
                  value.gelar ===
                    list.master_kelompok_honor.nm_kelompok_honor &&
                  value.thnajar === list.tahun_anggaran.toString() &&
                  "S" === list.kelompok_kelas &&
                  value.id_jabfung === list.id_jabfung.toString()
                ) {
                  return list;
                }
              });
            } else {
              getHonor = this.listHonor.filter((list) => {
                if (
                  value.thnajar === list.tahun_anggaran.toString() &&
                  list.id_kelompok_honor === (value.id_jabfung ? 2 : 1) &&
                  value.sesi === list.kelompok_kelas
                ) {
                  return list;
                }
              });
            }

            item.honor = getHonor[0]?.nominal;
            item.jumlah =
              item.honor *
              item.jml_pertemuan *
              (item.bobot_sks == 4 ? 2 : item.bobot_sks);
            this.listhitungan.push(item);
            /*keuangan.Honorarium.postCustomPath("getHonorNgajar", prm).then(
              (val) => {
                item.honor = val?.data?.nominal;
                this.listhitungan.push(item);
              }
            );*/
          });

          this.getBulan();
          this.filterName();
        }
      );
    },
    filterName() {
      if (this.searchFilter.length > 3) {
        this.filterListhitungan = this.listhitungan.filter((fil) => {
          if (
            fil?.NM_DOSEN.toLowerCase().includes(this.searchFilter) ||
            fil?.kode_dosen.toLowerCase().includes(this.searchFilter) ||
            fil?.kode_mk.toLowerCase().includes(this.searchFilter) ||
            fil?.nm_mk.toLowerCase().includes(this.searchFilter)
          ) {
            return { ...fil };
          }
        });
      } else {
        this.filterListhitungan = this.listhitungan;
      }
    },
    getBulan() {
      let listBulan = [
        { id: "01", name: "Januari" },
        { id: "02", name: "Februari" },
        { id: "03", name: "Maret" },
        { id: "04", name: "April" },
        { id: "05", name: "Mei" },
        { id: "06", name: "Juni" },
        { id: "07", name: "Juli" },
        { id: "08", name: "Agustus" },
        { id: "09", name: "September" },
        { id: "10", name: "Oktober" },
        { id: "11", name: "November" },
        { id: "12", name: "Desember" },
      ];

      let tes = listBulan.filter((res) => {
        if (
          res.id === moment(this.piltgl1).format("MM") ||
          res.id === moment(this.piltgl2).format("MM")
        ) {
          return res;
        }
      });

      this.listBulan = tes;
    },
    getPertemuan(item) {
      this.panel.panelOpen = true;
      this.idData = item;
    },
    simpanHonor() {
      let val = {
        jenis: "Honor Mengajar",
        bulan: this.pilBulan,
        listHonor: this.listhitungan,
      };
      keuangan.PendataanHonor.insertData(val).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.alertSuccess = true;
          this.messageAlert = res.data.message;
        }
      });
    },
    async generateExcel() {
      console.log("woi");

      const rows = [
        [
          {
            value: "No",
            fontWeight: "bold",
          },
          {
            value: "Tahun Ajar",
            fontWeight: "bold",
          },
          {
            value: "Kode MK",
            fontWeight: "bold",
          },
          {
            value: "NAMA MK",
            fontWeight: "bold",
          },
          {
            value: "Kelas",
            fontWeight: "bold",
          },
          {
            value: "Sesi",
            fontWeight: "bold",
          },
          {
            value: "SKS",
            fontWeight: "bold",
          },
          {
            value: "Bobot SKS",
            fontWeight: "bold",
          },
          {
            value: "Kode Dosen",
            fontWeight: "bold",
          },
          {
            value: "Nama Dosen",
            fontWeight: "bold",
          },
          {
            value: "Status Dosen",
            fontWeight: "bold",
          },
          {
            value: "Tingkat",
            fontWeight: "bold",
          },
          {
            value: "Jenjang",
            fontWeight: "bold",
          },
          {
            value: "Jml Pertemuan",
            fontWeight: "bold",
          },
          {
            value: "Honor",
            fontWeight: "bold",
          },
          {
            value: "Jumlah",
            fontWeight: "bold",
          },
        ],
      ];

      if (this.listhitungan.length > 0) {
        let n = 1;
        console.log(this.listhitungan);
        this.listhitungan.forEach((item) => {
          rows.push([
            {
              type: Number,
              value: n,
            },
            {
              type: String,
              value: item.thnajar.toString(),
            },
            {
              type: String,
              value: item.kode_mk.toString(),
            },
            {
              type: String,
              value: item.nm_mk,
            },
            {
              type: String,
              value: item.nm_kls,
            },
            {
              type: String,
              value: item.sesi == "P" ? "PAGI" : "SORE",
            },
            {
              type: Number,
              value: parseInt(item?.sks_mk),
            },
            {
              type: Number,
              value: parseInt(item?.bobot_sks),
            },
            {
              type: String,
              value: item?.kode_dosen,
            },
            {
              type: String,
              value: item?.NM_DOSEN,
            },
            {
              type: String,
              value: item?.gelar
                ? item?.gelar
                : item?.THNS3 > 0 && item?.THNS3 != null
                ? "S3"
                : item?.THNS2 > 0 && item?.THNS2 != null
                ? "S2"
                : null,
            },
            {
              type: String,
              value: item?.STATUS_DOSEN == 1 ? "DOSEN TETAP" : "DOSEN LB",
            },
            {
              type: String,
              value: item?.nm_jabfung,
            },
            {
              type: Number,
              value: parseInt(item?.jml_pertemuan),
            },
            {
              type: Number,
              value: parseInt(item?.honor),
            },
            {
              type: Number,
              value:
                item?.honor *
                item?.jml_pertemuan *
                (item?.bobot_sks == 4 ? 2 : item?.bobot_sks),
            },
          ]);
          n++;
        });

        let blobDataJadwal = await writeXlsxFile(rows, {
          filePatch: "List Honor Mengajar Dosen.xlsx",
        });
        const blobData = new Blob([blobDataJadwal], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        saveAs(blobData, `List Honor Mengajar Dosen.xlsx`);
        this.exportExcelString = "Export Excel";
      }
    },
  },
});
</script>
