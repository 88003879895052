<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-2">
      <h2 class="text-lg font-medium mr-auto">Perhitungan Honor Soal</h2>
    </div>

    <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
    <AlertFailed v-if="alertFailed" :message="messageAlert" />

    <div class="md:flex mb-4 gap-2">
      <select v-model="pilSemester" class="form-select mb-2">
        <option
          v-for="semester in listSemester"
          :key="semester.id_smt"
          :value="semester.id_smt"
        >
          {{ semester.nm_smt }}
        </option>
      </select>
      <select v-model="pilJenis" class="form-select mb-2">
        <option value="UTS">UTS</option>
        <option value="UAS">UAS</option>
      </select>
      <button class="btn btn-primary mb-2" @click="getHonorSoal()">
        <SearchIcon></SearchIcon> Tampilkan
      </button>
    </div>

    <div
      v-if="listHitung.length > 0"
      class="intro-y block sm:flex items-center my-4 gap-3 rounded bg-primary-7 p-4"
    >
      <label class="sm:ml-auto font-bold"
        >Melakukan Penyimpanan ke Pendataan Honor :</label
      >
      <select v-model="pilBulan" class="sm:mt-0 form-select w-36">
        <option value="" selected disabled>Pilih Bulan</option>
        <option v-for="bulan in listBulan" :key="bulan.id" :value="bulan.id">
          {{ bulan.name }}
        </option>
      </select>
      <button
        class="btn btn-outline-primary hover:text-white"
        @click="simpanHonor()"
      >
        Simpan
      </button>
    </div>

    <div class="box">
      <table class="table">
        <thead>
          <tr class="bg-gray-600 text-white text-center h-14">
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-10"
            >
              No
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-8"
            >
              Semester
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-56"
            >
              DOSEN
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-12"
            >
              Jenis Ujian
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-1"
            >
              Jumlah
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-12"
            >
              Honor
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-12"
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody v-if="listHitung.length > 0">
          <tr
            v-for="(hitung, index) in listHitung"
            :key="index"
            class="hover:bg-gray-300 odd:bg-gray-200"
          >
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
            >
              {{ index + 1 }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
            >
              {{ hitung.nm_smt }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
            >
              <div class="text-gray-600">::: {{ hitung.kode_dosen }}</div>
              <h1 class="font-medium">{{ hitung.NM_DOSEN }}</h1>
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-center"
            >
              {{ hitung.jenis_ujian }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-center"
              @click="getListJadwalSoal(hitung)"
            >
              <button>
                {{ hitung.jml }} <SearchIcon class="w-4"></SearchIcon>
              </button>
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-right"
            >
              {{ rupiahLocal(hitung.honor) }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-right"
            >
              {{ rupiahLocal(hitung.honor * hitung.jml) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="7" class="text-gray-500">Data Belum Ada</td>
          </tr>
        </tbody>
      </table>
    </div>

    <SideOver :open="panel.panelOpen" :size="'max-w-5xl'" @close="closePanel">
      <template #title>Detail Jadwal Soal</template>
      <div class="h-full" aria-hidden="true"></div>
      <RINCI :detailrinci="listDetail" />
    </SideOver>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import moment from "moment";
import { HTTP } from "@/services/http";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertFailed from "@/components/alerts/AlertFailed.vue";
import { keuangan } from "@/services/models";
import SideOver from "@/layouts/main/SlideOver";
import RINCI from "./DetailSoal";

export default defineComponent({
  components: {
    AlertSuccess,
    AlertFailed,
    RINCI,
    SideOver,
  },
  setup() {
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });

    return {
      panel,
      panelOpen,
    };
  },
  data() {
    return {
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      pilSemester: "",
      pilJenis: "UTS",
      listSemester: [],
      listHitung: [],
      listDetail: [],
      pilBulan: "",
      listBulan: [],
    };
  },
  mounted() {
    this.getActiveSemester();
  },
  methods: {
    moment: function (date) {
      moment.locale("id");
      return moment(date);
    },
    panelPop() {
      this.panel.panelOpen = true;
    },
    closePanel(close) {
      this.panel.panelOpen = close;
    },
    rupiahLocal(nominal) {
      let rupiahLocal = Intl.NumberFormat("id-ID");
      if (nominal != null) {
        return "Rp. " + rupiahLocal.format(nominal);
      } else {
        return "0";
      }
    },
    getBulan() {
      let listBulan = [
        { id: "01", name: "Januari" },
        { id: "02", name: "Februari" },
        { id: "03", name: "Maret" },
        { id: "04", name: "April" },
        { id: "05", name: "Mei" },
        { id: "06", name: "Juni" },
        { id: "07", name: "Juli" },
        { id: "08", name: "Agustus" },
        { id: "09", name: "September" },
        { id: "10", name: "Oktober" },
        { id: "11", name: "November" },
        { id: "12", name: "Desember" },
      ];

      this.listBulan = listBulan;
    },
    getActiveSemester() {
      HTTP.get("is_active/semester_keu").then((res) => {
        let act = res.data.data.filter((prm) => prm.a_periode_aktif === 1);
        this.pilSemester = act[0].id_smt;
        this.listSemester = res.data.data;
      });
    },
    getHonorSoal() {
      let prm = {
        id_smt: this.pilSemester,
        jenis: this.pilJenis,
      };
      let hitung = [];
      keuangan.PerhiutnganHonor.postCustomPath("honor-soal", prm).then(
        (res) => {
          res.data.forEach((val) => {
            hitung = val;
            hitung.jumlah = val.jml * val.honor;
          });
          this.listHitung = res.data;
          this.getBulan();
        }
      );
    },
    getListJadwalSoal(item) {
      this.panel.panelOpen = true;
      this.listDetail = item;
    },
    simpanHonor() {
      let val = {
        jenis: "Honor Soal",
        bulan: this.pilBulan,
        listHonor: this.listHitung,
      };

      keuangan.PendataanHonor.insertData(val).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.alertSuccess = true;
          this.messageAlert = res.data.message;
        }
      });
      console.log(val);
    },
  },
});
</script>
