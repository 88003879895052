<template>
  <div>
    <div v-if="loading" class="block-loading">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
      <div class="text-center text-xs mt-2">Loading... Please Wait...</div>
    </div>
    <div class="grid grid-cols-12 gap-0 mb-3">
      <dl class="col-span-12 lg:col-span-6">
        <DataList :gray="true" :dt="'Tahun Akademik'">
          <template #dd>
            {{ $h.smt(detail.bayar?.smt) }}
          </template>
        </DataList>
        <DataList
          :gray="false"
          :dd="detail.bayar?.nm_pd"
          :dt="'Nama'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="$h.tanggal(detail.bayar?.KONF_TANGGAL)"
          :dt="'Tanggal Bayar'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="detail.bayar?.KONF_STAFF"
          :dt="'Admin Penagih'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="
            detail.bayar?.KONF_TANGGAL_VALIDASI
              ? $h.tanggal(detail.bayar?.KONF_TANGGAL_VALIDASI)
              : 'Belum divalidasi'
          "
          :dt="'Tanggal Validasi'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="$h.IDR(detail.bayar?.KONF_JUMLAH)"
          :dt="'Nominal Pembayaran'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="detail.bayar?.KONF_CARA"
          :dt="'Cara Pembayaran'"
        ></DataList>
        <DataList
          :gray="false"
          :dd="detail.bayar?.KONF_NAMA_PEMILIK"
          :dt="'Nama Akun'"
        ></DataList>
        <DataList
          :gray="true"
          :dd="detail.bayar?.KONF_CATATAN"
          :dt="'Catatan'"
        ></DataList>
        <button
          class="btn btn-success btn-sm mt-4"
          :disabled="!(detail.bayar?.KONF_VALIDASI == 'N')"
          @click="validasi"
        >
          <check-circle-icon class="mr-2"></check-circle-icon>
          Validasi
        </button>
      </dl>
      <dl class="col-span-12 lg:col-span-6">
        <div v-if="!this.detail.bayar?.BUKTI" class="mx-3">
        </div>
        <div v-else class="mx-3">
          <img class="rounded-md" :src="buktiUrl" />
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
// import { HTTPKEU } from "@/services/http";
// import axios from "axios";
import Alert from "../../components/alerts/Alert";
import DataList from "./DataList";
import moment from "moment";
// import detail from "@/views/mahasiswa/pesertadidik/Detail.vue";
export default {
  name: "DetailTransaksi",
  components: { DataList, Alert },
  props: {
    detail: {
      type: Object,
      default: null,
    },
    process: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // transaksiBank: null,
      // allowValidasi: false,
      // statusValidasi: {
      //   msg: null,
      //   status: null,
      // },
      buktiUrl: 'https://daftar.mdp.ac.id/upload/bukti/' + this.detail.bayar?.BUKTI,
      loading: false,
      model: { id_bayar: this.detail.bayar.id_bayar, KONF_VALIDASI: "Y", KONF_TANGGAL_VALIDASI: moment().format('YYYY-MM-DD'), KONF_TANGGAL_BATAL_VALIDASI: "0000-00-00" },
    };
  },
  watch: {
    detail: {
      handler: function (h) {
        // this.transaksiBank = null;
        // this.allowValidasi = false;
      },
      immediate: true,
    },
    process: {
      handler: function (h) {
        // if (h == false) {
        //   // this.transaksiBank = null;
        //   this.allowValidasi = false;
        // }
        // else {
        //   this.allowValidasi = true;
        // }
      },
      immediate: true,
    },
  },
  computed() {
  },
  mounted() {
    // console.log(this.allowValidasi);
  },
  methods: {
    // getSubcomList() {
    //   HTTPKEU.get("master/subcomp").then((res) => {
    //     this.subcomps = res.data;
    //   });
    // },
    // getProdukList() {
    //   HTTPKEU.get("master/produk").then((res) => {
    //     this.produks = res.data;
    //   });
    // },
    // cekTransaksiBank(bank, idtrx) {
    //   this.loading = true;
    //   this.transaksiBank = null;
    //   this.allowValidasi = false;
    //   var data = new FormData();
    //   data.append("id", idtrx);
    //
    //   var config = {
    //     method: "post",
    //     url: "https://sw.mdp.net.id/bill",
    //     headers: {
    //       Accept: "application/json",
    //     },
    //     data: data,
    //   };
    //   let that = this;
    //   axios(config)
    //     .then(function (res) {
    //       if (res.status === 200) {
    //         that.statusValidasi.status =
    //           res.data?.error?.code == 0 ? true : false;
    //         that.statusValidasi.msg =
    //           res.data?.error?.code == 0
    //             ? "Transaksi BANK ditemukan!"
    //             : res.data?.error?.msg;
    //         if (res.data?.error?.code == "0") {
    //           that.transaksiBank = res.data?.data?.TransactionData;
    //           that.allowValidasi = true;
    //         }
    //       } else {
    //         that.statusValidasi.status = false;
    //         that.statusValidasi.msg = res.message;
    //       }
    //     })
    //     .catch(function () {
    //       that.statusValidasi.status = false;
    //       that.statusValidasi.msg = "Tejadi error pada API Payment Gateway";
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    // clearV() {
    //   this.statusValidasi.status = null;
    //   this.statusValidasi.msg = null;
    // },
    validasi() {
      this.$emit("validasiTransaksi", this.model);
    },
  },
};
</script>

<style scoped></style>
