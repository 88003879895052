<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Rekap Formulir PMB<br/>
        <span class="text-sm font-medium" data-v-f48bdcdc=""
        >Perhitungan Formulir PMB</span
        >
      </h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <AlertSuccess v-if="alertSuccess" :message="messageAlert"/>
      <AlertFailed v-if="alertFailed" :message="messageAlert"/>

      <div class="md:flex mb-4 gap-2">
        <select v-model="pilSemester" class="form-select mb-2">
          <option
            v-for="semester in listSemester"
            :key="semester.id_smt"
            :value="semester.id_smt"
          >
            {{ semester.nm_smt }}
          </option>
        </select>
        <select v-model="pilFakultas" class="form-select mb-2">
          <option
            v-for="fak in listFakultas"
            :key="fak.id"
            :value="fak.kode_huruf"
          >
            {{ fak.nama }}
          </option>
        </select>
        <input v-model="piltgl1" type="date" class="form-control mb-2"/>
        <input v-model="piltgl2" type="date" class="form-control mb-2"/>

        <button class="btn btn-primary mb-2" @click="getRekap()">
          <SearchIcon></SearchIcon>
          Tampilkan
        </button>

        <button
          v-if="listhitungan.length > 0"
          class="btn btn-outline-secondary rounded mb-2"
          @click="catakRekap()"
        >
          <DownloadIcon class="mr-1"></DownloadIcon>
          Cetak
        </button>
      </div>

      <table class="table">
        <thead>
        <tr class="bg-gray-600 text-white text-center">
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            Nomor
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            Nama
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            Tanggal Bayar
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            Biaya
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            Fakultas
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            Catatan
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(item, index) in listhitungan"
          v-if="listhitungan.length > 0"
          :key="index"
          class="hover:bg-gray-300 odd:bg-gray-200"
        >
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.no_ujian }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.nm_pd }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.tanggal(item.KONF_TANGGAL_VALIDASI) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item.KONF_JUMLAH) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ ((item.fakultas == 'fik') ? 'Fakultas Ilmu Komputer dan Rekayasa' : ((item.fakultas == 'feb') ? 'Fakultas Ekonomi dan Bisnis' : 'Universitas MDP')) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.KONF_CATATAN }}
          </td>
        </tr>
        <tr class="hover:bg-gray-300 odd:bg-gray-200">
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40 text-right font-bold text-lg"
            colspan="3"
          >
            TOTAL *
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-40 font-bold text-lg"
            colspan="4"
          >
            {{ $h.IDR(totalPembelian) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, reactive} from "vue";
import moment from "moment";
import {keuangan} from "@/services/models";
import {HTTP, HTTPKEU} from "@/services/http";
// import RINCI from "./DetailPertemuan";
import SideOver from "@/layouts/main/SlideOver";
import writeXlsxFile from "write-excel-file";
import {saveAs} from "file-saver";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertFailed from "@/components/alerts/AlertFailed.vue";

export default defineComponent({
  components: {
    // RINCI,
    SideOver,
    AlertSuccess,
    AlertFailed,
  },
  setup() {
    let panelOpen = ref(false);
    let panel = reactive({panelOpen});

    return {
      panel,
      panelOpen,
    };
  },
  data() {
    return {
      pilSemester: 0,
      pilFakultas: 0,
      piltgl1: moment().format("YYYY-MM-DD"),
      piltgl2: moment().format("YYYY-MM-DD"),
      listhitungan: [],
      listSemester: [],
      listFakultas: [],
      totalPembelian: [],
      // listHonor: [],
      // idData: [],
      // listBulan: [],
      // pilBulan: "",
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
    };
  },
  watch: {
    // pilSemester: {
    //   handler: function () {
    //     this.getListHonorarium();
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    this.getActiveSemester();
    this.getActiveFakultas();
    this.getRekap();
  },
  methods: {
    moment: function (date) {
      moment.locale("id");
      return moment(date);
    },
    panelPop() {
      this.panel.panelOpen = true;
    },
    closePanel(close) {
      this.panel.panelOpen = close;
    },
    getActiveSemester() {
      HTTP.get("is_active/semester_keu").then((res) => {
        let act = res.data.data.filter((req) => req.a_periode_aktif === 1);
        this.pilSemester = act[0].id_smt;
        this.listSemester = res.data.data;
      });
    },
    getActiveFakultas() {
      HTTP.get("is_active/masterfakultas").then((res) => {
        let act = res.data.data.filter((req) => req.status === 1);
        this.pilFakultas = act[0].id;
        this.listFakultas = res.data.data;

        this.listFakultas.push({
          id: "0",
          kode_huruf: "0",
          nama: "Universitas MDP"
        });
      });
    },
    getRekap() {
      this.alertSuccess = false;
      this.alertFailed = false;

      let postData = {
        id_smt: this.pilSemester,
        kode_fakultas: this.pilFakultas,
        tgl1: this.piltgl1,
        tgl2: this.piltgl2,
      };

      this.listhitungan = [];

      keuangan.Pmb.postCustomPath("rekap_bayar_formulir", postData).then(
        (res) => {
          this.totalPembelian = 0;
          res.data.forEach((item) => {
            this.totalPembelian = this.totalPembelian + item.KONF_JUMLAH;
            this.listhitungan.push(item);
          });

          console.log(this.totalPembelian);
        }
      );
    },
    catakRekap() {
      // this.downloadKontrak = "Tunggu Sebentar...";
      let postData = {
        // id_rekrutmen: this.dataRekrutmenref.id_rekrutmen,
        id_smt: this.pilSemester,
        kode_fakultas: this.pilFakultas,
        tgl1: this.piltgl1,
        tgl2: this.piltgl2,
      };
      keuangan.Pmb.postCustomPathBlob("cetak_rekap_bayar_formulir", postData).then(
        (res) => {
          // console.log(res);
          let blobFile = new Blob([res.data], {type: "application/pdf"});
          var fileURL = URL.createObjectURL(blobFile);
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            postData.tgl1 + `-` + postData.tgl1 + `.pdf`
            // `${this.kodeMK}-${this.kodeDosen}-${this.kelas}.pdf`
          );
          document.body.appendChild(fileLink);

          // this.downloadKontrak = "Download Kontrak Kerja";

          fileLink.click();
          window.open(fileURL);
        }
      );
    },
    // rupiahLocal(nominal) {
    //   let rupiahLocal = Intl.NumberFormat("id-ID");
    //   if (nominal != null) {
    //     return "Rp. " + rupiahLocal.format(nominal);
    //   } else {
    //     return "0";
    //   }
    // },
    // getListHonorarium() {
    //   HTTPKEU.get("list/honorarium/getListHonor/" + this.pilSemester).then(
    //     (res) => {
    //       this.listHonor = res.data;
    //     }
    //   );
    // },
    // getDataHitungNgajar() {
    //   this.alertSuccess = false;
    //   this.alertFailed = false;
    //
    //   let prm = {
    //     id_smt: this.pilSemester,
    //     tgl1: this.piltgl1,
    //     tgl2: this.piltgl2,
    //   };
    //   this.listhitungan = [];
    //   keuangan.Pmb.postCustomPath("rekap_bayar_formulir", prm).then(
    //     (res) => {
    //       console.log(res);
    //       res.data.forEach((item) => {
    //         this.listhitungan.push(item);
    //       });
    //       // res.data.forEach((item) => {
    //       //   let value = {
    //       //     sesi: item.sesi,
    //       //     thnajar: item.thnajar,
    //       //     status_dosen: item.STATUS_DOSEN,
    //       //     id_jabfung: item.id_jabfung
    //       //       ? item.id_jabfung
    //       //       : item.id_jabfung_lb,
    //       //     gelar: item.gelar
    //       //       ? item.gelar
    //       //       : item.THNS3 > 0 && item.THNS3 != null
    //       //       ? "S3"
    //       //       : item.THNS2 > 0 && item.THNS2 != null
    //       //       ? "S2"
    //       //       : null,
    //       //   };
    //       //
    //       //   let getHonor;
    //       //   if (value.sesi == "S") {
    //       //     getHonor = this.listHonor.filter((list) => {
    //       //       if (
    //       //         value.gelar ===
    //       //           list.master_kelompok_honor.nm_kelompok_honor &&
    //       //         parseFloat(value.thnajar) === list.tahun_anggaran &&
    //       //         value.sesi === list.kelompok_kelas &&
    //       //         list.id_jabfung ===
    //       //           (value.id_jabfung
    //       //             ? value.id_jabfung
    //       //             : "31.000000000000000000000000000000")
    //       //       ) {
    //       //         return list;
    //       //       }
    //       //     });
    //       //   } else if (value.status_dosen == 2) {
    //       //     getHonor = this.listHonor.filter((list) => {
    //       //       if (
    //       //         value.gelar ===
    //       //           list.master_kelompok_honor.nm_kelompok_honor &&
    //       //         value.thnajar === list.tahun_anggaran.toString() &&
    //       //         "S" === list.kelompok_kelas &&
    //       //         value.id_jabfung === list.id_jabfung.toString()
    //       //       ) {
    //       //         return list;
    //       //       }
    //       //     });
    //       //   } else {
    //       //     getHonor = this.listHonor.filter((list) => {
    //       //       if (
    //       //         value.thnajar === list.tahun_anggaran.toString() &&
    //       //         list.id_kelompok_honor === (value.id_jabfung ? 2 : 1) &&
    //       //         value.sesi === list.kelompok_kelas
    //       //       ) {
    //       //         return list;
    //       //       }
    //       //     });
    //       //   }
    //       //
    //       //   item.honor = getHonor[0].nominal;
    //       //   item.jumlah =
    //       //     getHonor[0].nominal *
    //       //     item.jml_pertemuan *
    //       //     (item.bobot_sks == 4 ? 2 : item.bobot_sks);
    //       //   this.listhitungan.push(item);
    //       //   /*keuangan.Honorarium.postCustomPath("getHonorNgajar", prm).then(
    //       //     (val) => {
    //       //       item.honor = val?.data?.nominal;
    //       //       this.listhitungan.push(item);
    //       //     }
    //       //   );*/
    //       // });
    //       //
    //       // this.getBulan();
    //     }
    //   );
    // },
  },
});
</script>
