<template>
  <div>
    <Alert
      v-if="statusSimpan.status != null"
      id="alerts1"
      :type="statusSimpan.status ? 'alert-success' : 'alert-danger'"
      :dismissable="true"
      >{{ statusSimpan.msg }}</Alert
    >
    <h1>Form Biaya UK I</h1>
    <form @submit.prevent="submit">
      <div class="grid grid-cols-2 mt-3 gap-3 mb-2">
        <div class="col-span-1">
          <label class="form-label">Tahun Ajaran</label>
          <input
            class="form-control"
            :value="smt?.thn_ajaran?.nm_thn_ajaran"
            readonly
            disabled
          />
        </div>
        <div class="col-span-1">
          <label class="form-label">Semester</label>
          <input class="form-control" :value="smt?.nm_smt" readonly disabled />
        </div>
      </div>

      <div class="grid grid-cols-3 mt-3 gap-3 mb-2">
        <div class="col-span-1">
          <label class="form-label">Fakultas</label>
          <select v-model="fakultas" class="form-select">
            <option
              v-for="fak in listOptionsState?.faculties"
              :key="fak.id"
              :value="fak.value"
            >
              {{ fak.text }}
            </option>
          </select>
        </div>
        <div class="col-span-1">
          <label class="form-label">SKS Diambil</label>
          <select v-model="jenisSelected" class="form-select">
            <option value="2-4">2-4 SKS</option>
            <option value="5-8">5-8 SKS</option>
          </select>
        </div>
        <div class="col-span-1">
          <label class="form-label">Biaya</label>
          <input v-model="biaya" required class="form-control" />
        </div>
      </div>

      <button type="submit" class="btn btn-success w-50" @click="submit">
        Simpan
      </button>
    </form>
  </div>
</template>

<script>
import { HTTPKEU } from "../../../services/http";
import { option } from "@/utils/listoptions";
import Alert from "../../../components/alerts/Alert";

export default {
  name: "FormUK1",
  components: { Alert },
  props: {
    smt: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      jenisSelected: "2-4",
      biaya: null,
      statusSimpan: {
        msg: null,
        status: null,
      },
      fakultas: 1,
    };
  },
  computed: {
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
  },
  watch: {
    smt: {
      handler: function (h) {},
      immediate: true,
    },
    model: {
      handler: function () {},
      immediate: true,
    },
  },
  beforeMount() {
    option.fetchFakultas();
  },
  mounted() {},
  methods: {
    submit() {
      this.statusSimpan.msg = null;
      this.statusSimpan.status = null;
      if (this.model.id_smt != null && this.biaya != null) {
        this.loading = true;

        HTTPKEU.post("master/biayasp", {
          id_smt: this.model.id_smt,
          id_fakultas: this.fakultas,
          skema: "UK I",
          jenis: this.jenisSelected,
          biaya: this.biaya,
        })
          .then((res) => {
            if (res.status == 200) {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = res.data?.message;
              this.statusSimpan.status = false;
            }
          })
          .catch((err) => {
            this.statusSimpan.msg = err.message;
            this.statusSimpan.status = false;
          })
          .finally(() => {
            this.$emit("uk1Done", true);
          });
      } else {
        this.statusSimpan.msg = "Tahun Akademik dan Biaya tidak boleh kosong";
        this.statusSimpan.status = false;
      }

      cash("#alerts1").addClass("show");
      cash("#alerts1").attr("style", "");
    },
    closeAndClear() {
      this.statusSimpan.msg = null;
      this.statusSimpan.status = null;
    },
  },
};
</script>

<style scoped></style>
