<template>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
        <tr class="text-center">
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
          ></th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            Tahun Ajaran
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            Fakultas
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            Jenjang (Prodi)
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            Keterangan
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in listMasterBiaya" :key="index">
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
          >
            <button
              class="btn btn-sm btn-warning text-xs px-2 py-1"
              @click="hapus(item.id)"
            >
              <TrashIcon class="w-4 mr-1" />
              Hapus
            </button>
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.ta }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40 text-center"
          >
            <div v-if="item.fakultas != null">
              {{ item.fakultas }}
            </div>
            <div v-else><i>Semua</i></div>
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40 text-center"
          >
            <div v-if="item.jenjang != null">
              {{ item.jenjang }}
              <span v-if="item.prodi != null">{{ item.prodi }}</span>
            </div>
            <div v-else><i>Semua</i></div>
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            <div v-if="item.skema == 'UK I'">
              <div>SKS Diambil : <b>{{ item.jenis }}</b></div>
              <div>
                Biaya :
                <div
                  class="py-1 px-2 rounded-full text-xs bg-gray-300 text-black text-center font-small"
                >
                  {{ $h.IDR(item.biaya) }}
                </div>
              </div>
            </div>
            <div v-if="item.skema == 'UK II'">
              <div class="mb-1">
                Tahun (Angkatan) :
                <div
                  class="py-1 px-2 rounded-full text-xs bg-gray-200 text-black text-center font-small"
                >
                  {{ item.angkatan }}
                </div>
              </div>
              <div class="mb-1">
                Waktu Kuliah :
                <div
                  v-if="item.jenis == 'Malam'"
                  class="py-1 px-2 rounded-full text-xs bg-gray-500 text-white text-center font-small"
                >
                  {{ item.jenis }}
                </div>
                <div
                  v-if="item.jenis == 'Pagi'"
                  class="py-1 px-2 rounded-full text-xs bg-green-300 text-black text-center font-small"
                >
                  {{ item.jenis }}
                </div>
              </div>
              <div>
                Biaya :
                <div
                  class="py-1 px-2 rounded-full text-xs bg-gray-300 text-black text-center font-small"
                >
                  {{ $h.IDR(item.biaya) }}
                </div>
              </div>
            </div>

            <div v-if="item.skema == 'Biaya ADM'">
              {{ $h.IDR(item.biaya) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TableSkemaBiaya",
  props: {
    listMasterBiaya: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      listMhs: [],
    };
  },
  computed: {},
  watch: {
    listMasterBiaya: {
      handler: function () {},
      immediate: true,
    },
  },
  methods: {
    hapus(id) {
      this.$emit("hapusMasterBiaya", id);
    },
  },
};
</script>

<style scoped></style>
