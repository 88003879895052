<template>
  <div>
    <div
      class="intro-y box shadow-lg p-2 pl-4 mb-4 flex items-center bg-primary-8 text-white gap-2"
    >
      <div
        class="text-lg font-bold self-center p-2 mt-1 text-center text-white rounded"
      >
        {{ detailrinci.nm_kls }}
      </div>
      <div class="pl-4 mr-auto border-l">
        <div class="font-bold">
          <CalendarIcon class="w-4"></CalendarIcon> {{ detailrinci.thnajar }}
        </div>
        <h1 class="text-lg font-medium truncate">
          {{ detailrinci.nm_mk }} ({{ detailrinci.kode_mk }})
        </h1>
        <h2 class="text-gray-400 mt-1">
          {{ detailrinci.kode_dosen }} |
          {{ detailrinci.NM_DOSEN }}
        </h2>
      </div>
      <div class="p-3 text-xl">
        <span
          class="font-bold"
          :class="detailrinci.sesi == 'P' ? 'text-blue-100' : 'text-yellow-400'"
        >
          <span v-if="detailrinci.sesi == 'P'"> <SunIcon></SunIcon> PAGI </span>
          <span v-else> <MoonIcon></MoonIcon> SORE </span>
        </span>
      </div>
    </div>
    <div class="intro-y pb-3">
      <table class="table mb-3">
        <thead>
          <tr class="bg-gray-600 text-white text-center text-xs h-20">
            <th class="border">Pert</th>
            <th class="border">Materi</th>
            <th class="border">Hadir</th>
            <th class="border">Izin</th>
            <th class="border">Alpha</th>
            <th class="border">Jenis Pertemuan</th>
            <th class="border">Status</th>
          </tr>
        </thead>
        <tbody v-if="listPertemuan.length > 0">
          <tr
            v-for="(rinci, index) in listPertemuan"
            :key="index"
            :class="
              rinci?.jenis_pertemuan.toUpperCase() == 'UTS' ||
              rinci?.jenis_pertemuan.toUpperCase() == 'UAS'
                ? 'bg-yellow-400'
                : ''
            "
            class="odd:bg-gray-200 hover:bg-gray-300"
          >
            <td class="border text-xs">
              {{ rinci.pertemuan_ke }}
            </td>
            <td class="border text-sm">
              <div>
                <div class="text-gray-500 text-xs">
                  {{ moment(rinci.tanggal).format("dddd, DD MMMM YYYY") }}
                </div>
                <div>{{ rinci.materi }}</div>
              </div>
            </td>
            <td class="border text-xs">{{ rinci.hadir }}</td>
            <td class="border text-xs">{{ rinci.izin }}</td>
            <td class="border text-xs">{{ rinci.alfa }}</td>
            <td class="border text-xs">{{ rinci.jenis_pertemuan }}</td>
            <td class="border text-xs">
              {{ rinci.selesai === 0 ? "Belum" : "Selesai" }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="7" class="border">{{ dataLoad }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import { perkuliahan } from "@/services/models";

export default defineComponent({
  props: {
    detailrinci: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataLoad: "Belum ada Data",
      listPertemuan: [],
    };
  },
  watch: {
    detailrinci: {
      handler: function () {
        this.getPertemuan();
      },
      immediate: true,
    },
  },
  methods: {
    moment: function (date) {
      moment.locale("id");
      return moment(date);
    },
    async getPertemuan() {
      this.dataLoad = "Menunggu...";
      await perkuliahan.Pertemuan.getCustomPath(
        "getRekapAbsensi/" + this.detailrinci.id_kls
      ).then((res) => {
        if (res.data.code === 200) {
          this.listPertemuan = res.data.data;
          this.dataLoad = "";
        } else {
          this.dataLoad = "Belum ada Data";
        }
      });
    },
  },
});
</script>
