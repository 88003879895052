<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Master Biaya Semester Pendek</h2>
      <div
        class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
      >
        <router-link
          tag="a"
          class="btn btn-success w-50 mt-3 mb-2"
          :to="{ name: 'tagihan-sp-create' }"
        >
          Buat Tagihan SP
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div v-if="loading" class="block-loading">
          <LoadingIcon icon="three-dots" class="w-8 h-8" />
          <div class="text-center text-xs mt-2">
            Loading data... Please Wait...
          </div>
        </div>
        <div class="intro-y box p-3">
          <div class="grid grid-cols-4 gap-2">
            <div class="col-span-1">
              <label class="form-label">Tahun Akademik</label>
              <TailSelectSelector
                :list-options="
                  listOptionsState?.semester?.filter((a) => a.smt == '3')
                "
                @cbox-select="selectSemester"
              ></TailSelectSelector>
            </div>
            <div class="col-span-1">
              <label class="form-label">Skema Biaya</label>
              <select v-model="skemaSelected" class="form-select">
                <option value="UK I">UK I</option>
                <option value="UK II">UK II</option>
                <option value="Biaya ADM">Biaya ADM</option>
              </select>
            </div>
            <div class="col-span-1">
              <button
                type="button"
                class="btn btn-primary w-50 mt-7"
                @click="cariData"
              >
                Cari Data
              </button>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-7">
              <TableSkemaBiaya
                :list-master-biaya="listMasterBiaya"
                @hapusMasterBiaya="hapusMasterBiaya"
              ></TableSkemaBiaya>
            </div>
            <div class="col-span-5">
              <FormUK1
                v-if="skemaSelected == 'UK I'"
                :model="model"
                :smt="smt"
                @uk1Done="cariData"
              />
              <FormUK2
                v-if="skemaSelected == 'UK II'"
                :model="model"
                :smt="smt"
                @uk2Done="cariData"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalDialog
      :question="'Anda yakin ingin menghapus Master Biaya Semester Pendek?'"
    >
      <template #delete-button>
        <button type="button" class="btn btn-danger w-24" @click="okKonfirm">
          Ya
        </button>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import TableSkemaBiaya from "./TablesSkemaBiaya";
import TailSelectSelector from "@/components/form/TailSelectSelector";
import { HTTPKEU } from "../../../services/http";

import { option } from "@/utils/listoptions";
import FormUK1 from "./FormUK1";
import FormUK2 from "./FormUK2";
import ModalDialog from "../../../components/utility/ModalDialog";
export default {
  name: "Main",
  components: {
    ModalDialog,
    FormUK1,
    FormUK2,
    TableSkemaBiaya,
    TailSelectSelector,
  },
  data() {
    return {
      loading: false,
      smt: { a_periode_aktif: 0 },
      listMasterBiaya: [],
      statusSimpan: {
        msg: null,
        status: null,
      },
      smtSelected: null,
      skemaSelected: "UK I",
      tahunAnggaran: null,
      model: {
        skema: null,
        id_smt: null,
      },
      statusHapus: {
        msg: null,
        status: null,
      },
      masterbiayaid: null,
    };
  },
  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2016; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
  },
  beforeMount() {
    option.fetchSemester(this.filterData);
    option.fetchProdi(this.filterData, true);
  },
  methods: {
    selectProdi(curr, id) {
      this.smsSelected = id;
    },
    selectSemester(curr, id) {
      this.smtSelected = id;
      this.smt = curr[0];
    },
    cariData() {
      this.loading = true;

      HTTPKEU.post("master/biayasp/getBiayaBySkemaSmt", {
        skema: this.skemaSelected,
        id_smt: this.smtSelected,
      })
        .then((res) => {
          if (res.status == 200) {
            this.listMasterBiaya = res?.data;
            this.model.id_smt = this.smtSelected;
            this.model.skema = this.skemaSelected;
            console.log(this.listMasterBiaya);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hapusMasterBiaya(id) {
      this.masterbiayaid = id;
      cash("#delete-modal").modal("show");
    },
    okKonfirm() {
      this.statusHapus.status = null;
      this.statusHapus.msg = null;
      HTTPKEU.post("master/biayasp/" + this.masterbiayaid, {
        _method: "DELETE",
        id: this.masterbiayaid,
      })
        .then((res) => {
          if (res.status === 200) {
            this.statusHapus.status = true;
            this.statusHapus.msg = res.data.message;
            this.cariData();
          } else {
            this.statusHapus.status = false;
            this.statusHapus.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusHapus.status = false;
          this.statusHapus.msg = e.message;
        });
      cash("#delete-modal").modal("hide");
      this.masterbiayaid = null;
    },
  },
};
</script>

<style scoped></style>
