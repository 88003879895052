<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">
          Validasi Transaksi Pembayaran
        </h2>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>
          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form
                id="tabulator-html-filter-form"
                class="xl:flex sm:mr-auto"
                @submit.prevent="onFilter"
              >
                <div class="sm:flex items-center sm:mr-4">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Filter By</label
                  >
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="nm_pd">Nama</option>
                  </select>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <select
                    v-if="filter.field == 'SubComp'"
                    v-model="filter.value"
                    class="form-select"
                    placeholder="Pilih SubComp"
                  >
                    <option
                      v-for="sc in subcomps"
                      :key="sc.Kode"
                      :value="sc.Kode"
                    >
                      {{ sc.Keterangan }}
                    </option>
                  </select>
                  <select
                    v-if="filter.field == 'KodeProduk'"
                    v-model="filter.value"
                    class="form-select"
                    placeholder="Pilih Produk"
                  >
                    <option
                      v-for="pr in produks"
                      :key="pr.kode"
                      :value="pr.kode"
                    >
                      {{ pr.Keterangan }} ({{ pr.kode }})
                    </option>
                  </select>
                  <input
                    v-if="filter.field == 'nm_pd' || filter.field == 'NO_VA'"
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="resetFilter"
                  >
                    Reset
                  </button>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="onPrint"
                >
                  <PrinterIcon class="w-4 h-4 mr-2" /> Print
                </button>
                <div class="dropdown w-1/2 sm:w-auto">
                  <button
                    class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                    aria-expanded="false"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export
                    <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        id="tabulator-export-xlsx"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportXlsx"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                      </a>
                      <a
                        id="tabulator-export-html"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportHtml"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 tabulator"
              ></div>
            </div>
          </div>
        </div>
      </div>

<!--      <ModalDialogPositive-->
<!--        :id="'validasi-modal'"-->
<!--        :question="-->
<!--          'Anda yakin ingin memvalidasi pembayaran tagihan ' +-->
<!--          detail.tagihan?.NamaProduk +-->
<!--          ' ' +-->
<!--          detail.tagihan?.Nama +-->
<!--          '?'-->
<!--        "-->
<!--      >-->
<!--        <template #delete-button>-->
<!--          <button-->
<!--            type="button"-->
<!--            class="btn btn-success w-24"-->
<!--            @click="konfirmValidasi"-->
<!--          >-->
<!--            Ya-->
<!--          </button>-->
<!--        </template>-->
<!--      </ModalDialogPositive>-->
    </div>

    <SlideOver :open="panel.panelOpen" size="max-w-5xl" @close="closePanel">
      <template #title>Detail Transaksi Pembayaran</template>
      <div class="h-full" aria-hidden="true">
        <div class="mt-2 mb-5">
        <Alert
          v-if="statusValidasi.status != null"
          :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusValidasi.msg }}</Alert
        >
        </div>
<!--        <div-->
<!--          v-if="confirmValidasi"-->
<!--          class="p-3 text-center border border-yellow-500 mb-3 font-medium text-lg text-yellow-700"-->
<!--        >-->
<!--          Anda yakin ingin memvalidasi pembayaran tagihan-->
<!--          <b>{{ detail.tagihan?.NamaProduk }}</b> mahasiswa-->
<!--          <b>{{ detail.tagihan?.Nama }}</b-->
<!--          >?-->
<!--          <div class="content-center mt-2 text-xs">-->
<!--            <select-->
<!--              v-model="model.account_code"-->
<!--              class="form-select form-select-sm w-auto mr-2 border-yellow-700"-->
<!--            >-->
<!--              <option value="700.100">Penerimaan PMB</option>-->
<!--              <option value="710.100">UK 1</option>-->
<!--              <option value="710.200">UK 2</option>-->
<!--              <option value="710.300">Biaya Semester Pendek</option>-->
<!--              <option value="710.300">Wisuda</option>-->
<!--            </select>-->
<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-success w-24"-->
<!--              @click="konfirmValidasi"-->
<!--            >-->
<!--              Ya-->
<!--            </button>-->

<!--            <button-->
<!--              type="button"-->
<!--              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-2"-->
<!--              @click="confirmValidasi = false"-->
<!--            >-->
<!--              Tidak-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
        <DetailTransaksi
          :detail="detail"
          :process="allowValidasi"
          @validasiTransaksi="validasiTransaksi"
        ></DetailTransaksi>
      </div>
    </SlideOver>
  </MainLayout>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import { useStore } from "vuex";
import feather from "feather-icons";
import { HTTPKEU } from "@/services/http";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import SlideOver from "@/layouts/main/SlideOver";
import { tblTabulator } from "@/utils/tabulator";
import { helper } from "@/utils/helper";
import store from "@/store";
// import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
// import { mahasiswa } from "@/services/models";
import DetailTransaksi from "./DetailTransaksi";
import Alert from "../../components/alerts/Alert";

export default {
  name: "RekapBiayaDaftar",
  components: {
    Alert,
    DetailTransaksi,
    // ModalDialogPositive,
    SlideOver,
    MainLayout,
  },

  setup() {
    const tableRef = ref();
    const tabulator = ref();
    const idData = ref();
    const store = useStore();
    const filter = reactive({
      field: "nm_pd",
      type: "like",
      value: "",
    });

    // detail
    let model = reactive({
      kode_bank: "BCA",
      kode_produk: null,
      kode_sub_comp: null,
      id_smt: null,
      npm: null,
      add_info: "",
      tgl_bayar: null,
      account_code: "710.100",
    });
    let panelOpen = ref(false);
    // let allowValidasi = ref(false);
    let panel = reactive({ panelOpen });
    let tagihan = null;
    let detail = reactive({ tagihan });

    const showDetail = (e, cell) => {
      idData.value = cell.getData().id;
      detail.bayar = cell.getData();
      // allowValidasi = (cell.getData().KONF_VALIDASI == 'N') ? true : false;
      panel.panelOpen = true;

      // console.log(detail.bayar);
      //
      // model.id_smt =
      //   cell.getData().TahunAkademik.slice(0, 4) + cell.getData().KD_Semester;
      // model.kode_produk = cell.getData().KodeProduk;
      // model.kode_bank = cell.getData().KodeBank;
      // model.kode_sub_comp = cell.getData().SubComp;
      // model.npm = cell.getData().NPM;
      // model.va = cell.getData().NO_VA;
      // model.no_upload = cell.getData().NO_UPLOAD;
      // model.add_info = "";
      // model.tgl_bayar = null;
    };

    //panel = true;
    // detail


    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          vertAlign: "middle",
          resizable: false,
          headerSort: false,
        },
        {
          formatter: "rownum",
          title: "NO",
          hozAlign: "left",
          vertAlign: "middle",
          resizable: false,
          headerSort: true,
          width: 80
        },
        {
          responsive: true,
          title: "Nama",
          field: "nm_pd",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().nm_pd +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().jalur +
              " / " +
              cell.getData().gelombang +
              " / " +
              cell.getData().sesi +
              "</div></div>"
            );
          },
        },
        {
          responsive: true,
          title: "Jumlah",
          field: "KONF_JUMLAH",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              helper.IDR(cell.getData().KONF_JUMLAH) +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              helper.tanggal(cell.getData().KONF_TANGGAL) +
              "</div></div>"
            );
          },
        },
        {
          title: "Cara Pembayaran",
          field: "KONF_CARA",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().KONF_CARA +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().KONF_CATATAN +
              "</div>"
            );
          },
        },
        {
          title: "Staff",
          field: "KONF_STAFF",
          hozAlign: "left",
          vertAlign: "middle",
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            return cell.getData().KONF_STAFF;
          },
        },
        {
          title: "Status",
          field: "KONF_VALIDASI",
          hozAlign: "left",
          vertAlign: "middle",
          width: 180,
          print: true,
          download: true,
          headerSort: false,
          formatter(cell) {
            let valid = cell.getData().KONF_VALIDASI;
            let status = "";
            if (valid === "Y") {
              status += `<div class="text-green-500 mr-1"><i data-feather="check-square" class="w-4 h-4 mr-1"></i> Telah Divalidasi</div>`;
            } else {
              status += `<div class="text-red-500 mr-1"><i data-feather="x-square" class="w-4 h-4 mr-1"></i> Belum Divalidasi</div>
              `;
            }

            return status;
          },
        },
        {
          title: "Validasi",
          field: "id",
          hozAlign: "left",
          vertAlign: "middle",
          width: 180,
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let valid = cell.getData().KONF_VALIDASI;
            let status = "";
            if (valid === "Y") {
              status += `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-link items-center">
                  <i data-feather="eye" class="w-3 h-3 mr-1"></i> Validasi Pembayaran
                </btn>
              </div>`;
            } else {
              status += `<div class="text-red-500 mr-1"><i data-feather="x-square" class="w-4 h-4 mr-1"></i> Belum Divalidasi</div>
              </div>`;
            }

            return status;
          },
          cellClick: showDetail,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "pmb/maba_daftar",
        columns,
        true
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "nm_pd";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Filter function
    const onFilterCustom = (field, type, value) => {
      tabulator.value.setFilter(field, type, value);
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onFilterCustom,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      idData,
      panel,
      detail,
      // allowValidasi,
      model,
    };
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      loading: false,
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,
      //
      // statusSimpan: {
      //   msg: null,
      //   status: null,
      // },
      // statusGetTagihan: {
      //   msg: null,
      //   status: null,
      // },
      statusValidasi: {
        msg: null,
        status: null,
      },
      panelOpen: false,
      needreset: false,
      dataPD: null,
      dosenPA: null,
      // transaksiBank: null,
      // allowValidasi: false,
      // confirmValidasi: false,
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2016; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
  },
  watch: {
    tabulatorLoaded: {
      handler: function (h) {
        if (h === true) {
          this.$nextTick(() => {
            store.commit("main/resetTabulatorDataLoaded");
          });

          if (this.needreset === true) {
            this.onResetFilter();
            this.needreset = false;
          }
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    option.fetchSemester();
    option.fetchProdi();
    this.getSubcomList();
    this.getProdukList();
  },
  mounted() {
    //this.cariData();
  },
  methods: {
    // fetchDataMahasiswa(d) {
    //   let id_pd = d?.id_pd;
    //   mahasiswa.PesertaDidik.getData(id_pd)
    //     .then((result) => {
    //       if (result?.status === 200) {
    //         this.dataPD = result.data.data;
    //         this.dosenPA = this.dataPD?.map_mhs;
    //         this.idRegPD = this.dosenPA?.id_reg_pd;
    //       }
    //     })
    //     .finally(() => {
    //       this.cariData(d?.npm);
    //     });
    // },
    validasiTransaksi(tb, it) {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
      // this.allowValidasi = true;

      HTTPKEU.post("pmb/validasi_biaya_daftar", { ...tb }).then((res) => {
          if (res.status === 200) {
              this.statusValidasi.status = true;
              this.statusValidasi.msg = res.data.message;

              this.resetFilter();
              // this.allowValidasi = false;
          }
          else {
              this.statusValidasi.status = false;
              this.statusValidasi.msg = res.data.message;
          }
      })
      .catch((e) => {
        this.statusValidasi.status = false;
        this.statusValidasi.msg = e.response?.data?.message;
      });
      // this.confirmValidasi = true;
      // this.allowValidasi = true;
      // if (tb == null) {
      //   this.model.add_info = it?.infoTambahan != "x" ? it?.infoTambahan : "";
      //   this.model.tgl_bayar = it?.infoTambahan != "x" ? it?.tglBayar : null;
      // }
      // console.log(tb);
    },
    // konfirmValidasi() {
    //   this.statusValidasi.status = null;
    //   this.statusValidasi.msg = null;
    //
    //   HTTPKEU.post("tagihan/validasi", { ...this.model })
    //     .then((res) => {
    //       if (res.status === 200) {
    //         this.statusValidasi.status = true;
    //         this.statusValidasi.msg = res.data.message;
    //       } else {
    //         this.statusValidasi.status = false;
    //         this.statusValidasi.msg = res.data.message;
    //       }
    //     })
    //     .catch((e) => {
    //       this.statusValidasi.status = false;
    //       this.statusValidasi.msg = e.response?.data?.message;
    //     })
    //     .finally(() => {
    //       this.cariData(this.model.npm);
    //       this.confirmValidasi = false;
    //     });
    // },
    closePanel(close) {
      this.panelOpen = close;
      this.panel.panelOpen = close;
      // this.transaksiBank = null;
      // this.allowValidasi = true;
      // this.confirmValidasi = false;
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    resetFilter() {
      this.needreset = true;
      this.filter.value = "";
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "pmb/maba_daftar",
        {},
        tblTabulator.ajaxConfig()
      );
    },
    getSubcomList() {
      HTTPKEU.get("list/subcomp").then((res) => {
        this.subcomps = res.data;
      });
    },
    getProdukList() {
      HTTPKEU.get("list/produk").then((res) => {
        this.produks = res.data;
      });
    },
  },
};
</script>

<style scoped></style>
