<template>
  <div>
    <div
      class="intro-y box shadow-lg p-2 pl-4 mb-4 flex items-center bg-primary-8 text-white gap-2"
    >
      <div
        class="text-lg font-bold self-center p-2 mt-1 text-center text-white rounded"
      >
        {{ detailrinci.jenis_ujian }}
      </div>
      <div class="pl-4 mr-auto border-l">
        <div class="font-bold">
          <CalendarIcon class="w-4"></CalendarIcon> {{ detailrinci.nm_smt }}
        </div>
        <h1 class="text-lg font-medium truncate">
          {{ detailrinci.NM_DOSEN }} ({{ detailrinci.kode_dosen }})
        </h1>
      </div>
      <div
        class="text-2xl mr-2 text-black border-2 border-green-600 p-3 bg-white rounded-tl-2xl rounded-br-2xl"
      >
        <div class="font-bold text-green-500">
          {{ rupiahLocal(detailrinci.honor * detailrinci.jml) }}
        </div>

        <div class="text-xs text-right text-gray-600">
          {{ rupiahLocal(detailrinci.honor) }} X
          {{ detailrinci.jml }}
        </div>
      </div>
    </div>

    <table class="table">
      <tr class="bg-gray-600 text-white text-center h-16">
        <th class="border">No</th>
        <th class="border">Mahasiswa</th>
        <th class="border">Judul</th>
        <th class="border">Bimbingan</th>
      </tr>
      <tbody v-if="listDetail.length > 0">
        <tr v-for="(item, index) in listDetail" :key="item.id" class="text-xs">
          <td class="border">{{ index + 1 }}</td>
          <td class="border">
            <div>
              <div class="text-gray-600">
                {{ item.mhs }}
              </div>
            </div>
          </td>
          <td class="border text-center">{{ item.judul }}</td>
          <td class="border">{{ item.total }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6" class="text-center">Tidak ada data</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import { keuangan } from "@/services/models";

export default defineComponent({
  props: {
    detailrinci: {
      type: Object,
      default: () => ({}),
    },
    idSmt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      listDetail: [],
    };
  },
  watch: {
    detailrinci: {
      handler: function () {
        this.getDetailBimbing();
      },
      immediate: true,
    },
  },
  methods: {
    moment: function (date) {
      moment.locale("id");
      return moment(date);
    },
    rupiahLocal(nominal) {
      let rupiahLocal = Intl.NumberFormat("id-ID");
      if (nominal != null) {
        return "Rp. " + rupiahLocal.format(nominal);
      } else {
        return "0";
      }
    },
    getDetailBimbing() {
      let prm = {
        id_smt: this.idSmt,
        jenis: this.detailrinci.jenis_ujian,
        id_dosen: this.detailrinci.kode_dosen,
      };

      keuangan.PerhiutnganHonor.postCustomPath("detail-dosbing", prm).then(
        (res) => {
          this.listDetail = res.data;
          console.log(this.listDetail);
        }
      );
    },
  },
});
</script>
