<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-2">
      <h2 class="text-lg font-medium mr-auto">
        Rekap Dosen Penguji Aktivitas Kegiatan Mahasiswa (KP/TA/Skripsi)
      </h2>
    </div>

    <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
    <AlertFailed v-if="alertFailed" :message="messageAlert" />

    <div class="md:flex mb-4 gap-2">
      <select v-model="pilSemester" class="form-select mb-2">
        <option
          v-for="semester in listSemester"
          :key="semester.id_smt"
          :value="semester.id_smt"
        >
          {{ semester.nm_smt }}
        </option>
      </select>
      <select v-model="pilJenis" class="form-select mb-2">
        <option value="pta">Proposal TA</option>
        <option value="ta">TA/Skripsi</option>
        <option value="tad3">TA D3</option>
        <option value="kp">Kerja Praktik</option>
      </select>
      <button class="btn btn-primary mb-2" @click="getHonorPenguji()">
        <SearchIcon></SearchIcon> Tampilkan
      </button>
    </div>

    <div
      v-if="listHitung?.length > 0"
      class="intro-y block sm:flex items-center my-4 gap-3 rounded bg-primary-7 p-4"
    >
      <label class="sm:ml-auto font-bold">Set nominal honor per judul :</label>
      <input v-model="nominalPerBimbingan" type="text" class="form-control" />
      <!--      <button
        class="btn btn-outline-primary hover:text-white"
        @click="setNominalHonor()"
      >
        Simpan
      </button>-->

      <button
        v-if="listHitung?.length > 0"
        class="btn btn-outline-secondary rounded"
        @click="generateExcel()"
      >
        <DownloadIcon class="mr-1"></DownloadIcon> Cetak
      </button>
      <!--      <label class="sm:ml-auto font-bold"
        >Melakukan Penyimpanan ke Pendataan Honor :</label
      >
      <select v-model="pilBulan" class="sm:mt-0 form-select w-36">
        <option value="" selected disabled>Pilih Bulan</option>
        <option v-for="bulan in listBulan" :key="bulan.id" :value="bulan.id">
          {{ bulan.name }}
        </option>
      </select>
      <button
        class="btn btn-outline-primary hover:text-white"
        @click="simpanHonor()"
      >
        Simpan
      </button>-->
    </div>

    <div class="box">
      <table class="table">
        <thead>
          <tr class="bg-gray-600 text-white text-center h-14">
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-10"
            >
              No
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-8"
            >
              Semester
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-56"
            >
              DOSEN
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-12"
            >
              Jenis Bimbingan
            </th>

            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-1"
            >
              Jumlah Judul
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-12"
            >
              Honor
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-12"
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody v-if="listHitung?.length > 0">
          <tr
            v-for="(hitung, index) in listHitung"
            :key="index"
            class="hover:bg-gray-300 odd:bg-gray-200"
          >
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
            >
              {{ index + 1 }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
            >
              {{ hitung.nm_smt }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
            >
              <div class="text-gray-600">::: {{ hitung.kode_dosen }}</div>
              <h1 class="font-medium">{{ hitung.NM_DOSEN }}</h1>
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-center"
            >
              {{ hitung.jenis_ujian }}
            </td>

            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-center"
            >
              {{ hitung.jml }}
            </td>

            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-right"
            >
              {{
                hitung.honor != nominalPerBimbingan
                  ? rupiahLocal(hitung.honor)
                  : rupiahLocal(nominalPerBimbingan)
              }}
            </td>

            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs text-right"
            >
              {{
                hitung.honor != nominalPerBimbingan
                  ? rupiahLocal(hitung.honor * hitung.jml)
                  : rupiahLocal(nominalPerBimbingan * hitung.jml)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="7" class="text-gray-500">Data Belum Ada</td>
          </tr>
        </tbody>
      </table>
    </div>

    <SideOver :open="panel.panelOpen" :size="'max-w-5xl'" @close="closePanel">
      <template #title>Detail Bimbingan</template>
      <div class="h-full" aria-hidden="true"></div>
      <DetailBimbing :detailrinci="listDetail" :id-smt="pilSemester" />
    </SideOver>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import moment from "moment";
import { HTTP } from "@/services/http";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertFailed from "@/components/alerts/AlertFailed.vue";
import { keuangan } from "@/services/models";
import SideOver from "@/layouts/main/SlideOver";
import { saveAs } from "file-saver";
import DetailBimbing from "./DetailBimbing";
import writeXlsxFile from "write-excel-file";

export default defineComponent({
  components: {
    AlertSuccess,
    AlertFailed,
    DetailBimbing,
    SideOver,
  },
  setup() {
    let panelOpen = ref(false);
    let panel = reactive({ panelOpen });

    return {
      panel,
      panelOpen,
    };
  },
  data() {
    return {
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      pilSemester: "",
      pilJenis: "ta",
      listSemester: [],
      listHitung: [],
      listDetail: [],
      pilBulan: "",
      listBulan: [],
      nominalPerBimbingan: 20000,
    };
  },
  watch: {
    pilJenis: {
      handler: function (newval, old) {
        if (old !== newval) {
          this.listHitung = [];
        }
        if (newval === "kp") {
          this.nominalPerBimbingan = 20000;
        } else if (newval === "pta") {
          this.nominalPerBimbingan = 15000;
        } else if (newval === "tad3") {
          this.nominalPerBimbingan = 28000;
        } else {
          this.nominalPerBimbingan = 40000;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getActiveSemester();
  },
  methods: {
    moment: function (date) {
      moment.locale("id");
      return moment(date);
    },
    panelPop() {
      this.panel.panelOpen = true;
    },
    closePanel(close) {
      this.panel.panelOpen = close;
    },
    rupiahLocal(nominal) {
      let rupiahLocal = Intl.NumberFormat("id-ID");
      if (nominal != null) {
        return "Rp " + rupiahLocal.format(nominal);
      } else {
        return "0";
      }
    },
    getBulan() {
      this.listBulan = [
        { id: "01", name: "Januari" },
        { id: "02", name: "Februari" },
        { id: "03", name: "Maret" },
        { id: "04", name: "April" },
        { id: "05", name: "Mei" },
        { id: "06", name: "Juni" },
        { id: "07", name: "Juli" },
        { id: "08", name: "Agustus" },
        { id: "09", name: "September" },
        { id: "10", name: "Oktober" },
        { id: "11", name: "November" },
        { id: "12", name: "Desember" },
      ];
    },
    getActiveSemester() {
      HTTP.get("is_active/semester_keu").then((res) => {
        let act = res.data.data.filter((prm) => prm.a_periode_aktif === 1);
        this.pilSemester = act[0].id_smt;
        this.listSemester = res.data.data;
      });
    },
    getHonorPenguji() {
      this.listHitung = [];
      let prm = {
        id_smt: this.pilSemester,
        jenis: this.pilJenis,
      };
      let hitung = [];
      keuangan.PerhiutnganHonor.postCustomPath("rekap-dosenpenguji", prm).then(
        (res) => {
          res.data.forEach((val) => {
            hitung = val;
            hitung.jumlah = val.jml * val.honor;
          });
          this.listHitung = res.data;
          this.getBulan();
        }
      );
    },
    getListJadwalSoal(item) {
      this.panel.panelOpen = true;
      this.listDetail = item;
    },

    setNominalHonor() {
      this.getHonorBimbingan();
    },
    async generateExcel() {
      let rows = [];
      rows = [
        [
          {
            value: "No",
            fontWeight: "bold",
          },
          {
            value: "Tahun Akademik",
            fontWeight: "bold",
          },
          {
            value: "Kode Dosen",
            fontWeight: "bold",
          },
          {
            value: "Nama Dosen",
            fontWeight: "bold",
          },
          {
            value: "Jenis Bimbingan",
            fontWeight: "bold",
          },
          {
            value: "Jumlah Judul",
            fontWeight: "bold",
          },
          {
            value: "Honor",
            fontWeight: "bold",
          },
          {
            value: "Jumlah",
            fontWeight: "bold",
          },
        ],
      ];

      if (this.listHitung.length > 0) {
        let n = 1;

        this.listHitung.forEach((item) => {
          rows.push([
            {
              type: Number,
              value: n,
            },
            {
              type: String,
              value: item.nm_smt.toString(),
            },
            {
              type: String,
              value: item?.kode_dosen,
            },
            {
              type: String,
              value: item?.NM_DOSEN,
            },
            {
              type: String,
              value: item?.jenis_ujian,
            },
            {
              type: Number,
              value: parseInt(item?.jml),
            },
            {
              type: Number,
              value:
                parseInt(item?.honor) != this.nominalPerBimbingan
                  ? parseInt(item?.honor)
                  : parseInt(this.nominalPerBimbingan),
            },
            {
              type: Number,
              value:
                parseInt(item?.honor) != this.nominalPerBimbingan
                  ? parseInt(item?.honor) * parseInt(item?.jml)
                  : parseInt(this.nominalPerBimbingan) * parseInt(item?.jml),
            },
          ]);
          n++;
        });

        let blobDataJadwal = await writeXlsxFile(rows, {
          filePatch: `List Jumlah Penguji ${this.pilJenis}.xlsx`,
        });
        const blobData = new Blob([blobDataJadwal], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        saveAs(blobData, `List Jumlah Penguji ${this.pilJenis}.xlsx`);
        this.exportExcelString = "Export Excel";
      }
    },
  },
});
</script>
